<template>
  <b-row>
    <b-col cols="12" sm="12">
      <b-card>
          <b-tabs pills vertical>
            <b-tab v-for="template in templates" :key="template.id" :active="template.id == 1">
              <template v-slot:title>
                <i class="fa" v-bind:class="{ 'fa-circle-o': template.isDefault, 'fa-dot-circle-o': !template.isDefault }"></i> {{ template.name }}
              </template>
              <p>{{ template.description }}</p>
              <b-row>
                <b-col sm="9">
                  <b-input-group prepend="Subject:" class="mb-3">
                    <b-input v-model="template.organizationTemplate.subject"></b-input>
                  </b-input-group>
                  <b-textarea :ref="`template-${template.id}`" v-on:blur.native="lostFocus(template)" v-model="template.organizationTemplate.body" :rows="20"></b-textarea>
                  <b-row class="mt-2">
                    <b-col sm="12" class="text-right">
                      <b-button class="btn-success" @click="save(template.organizationTemplate)">Save Template</b-button>
                      <b-button class="btn-primary ml-2" v-if="!template.isDefault" @click="useDefault(template.organizationTemplate)">Use Default</b-button>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col sm="3">
                  <b-card class="variables" header="Variables" v-if="template.variables.length > 0">
                    <b-badge variant="primary" href="#" @click="insert(variable.value)" v-for="variable in template.variables" :key="variable.id" v-b-tooltip.hover.d200.noninteractive :title="variable.description">{{ variable.name }}</b-badge>
                  </b-card>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Mailgun',
  data: () => {
    return {
      baseTemplates: [],
      returnFocus: false,
      cursorPosition: 0,
      currentTemplate: null
    }
  },
  computed: {
    ...mapGetters({
      account: 'global/account'
    }),
    templates () {
      const templates = this.baseTemplates.filter(o => !o.system).map((template) => {
        let customTemplate = null
        if (template.organizationTemplates && template.organizationTemplates.length === 1) {
          customTemplate = template.organizationTemplates[0]
        }
        const baseTemplate = {
          organizationId: this.account.organizationId,
          templateId: template.id,
          subject: template.subject,
          body: template.body
        }
        return {
          id: template.id,
          name: template.name,
          description: template.description,
          isDefault: !customTemplate,
          organizationTemplate: customTemplate || baseTemplate,
          variables: template.variables
        }
      })
      return templates.sort((a, b) => {
        if (a.id > b.id) return 1
        else if (a.id < b.id) return -1
        else return 0
      })
    }
  },
  methods: {
    lostFocus (template) {
      this.currentTemplate = template
    },
    insert (text) {
      const template = this.currentTemplate || null
      if (template) {
        if (this.$refs && this.$refs[`template-${template.id}`] && this.$refs[`template-${template.id}`].length === 1) {
          const ta = this.$refs[`template-${template.id}`][0]

          if (template && ta) {
            const startPosition = ta.selectionStart || ta.startPosition
            const endPosition = ta.selectionEnd || ta.endPosition
            let curPosition = startPosition
            let tempString = ta.value

            template.organizationTemplate.body = tempString.substring(0, startPosition) + text + tempString.substring(endPosition, tempString.length)

            setTimeout(() => {
              curPosition += text.length
              ta.selectionStart = ta.selectionEnd = curPosition
            }, 10)

            ta.focus()
          }
        }
      }
    },
    useDefault (organizationTemplate) {
      this.$api.email.organizationTemplates.delete({ id: organizationTemplate.id })
        .then((response) => {
          this.fetchTemplates()
        })
        .catch((err) => {
          this.$snotify.error('Error deleting custom template')
        })
    },
    async save (organizationTemplate) {
      if (organizationTemplate.id > 0) {
        await this.$api.email.organizationTemplates.update(organizationTemplate.id, organizationTemplate)
      } else {
        await this.$api.email.organizationTemplates.create(organizationTemplate)
      }
      this.$snotify.success('Template Saved')
      this.fetchTemplates()
    },
    fetchTemplates () {
      if (this.account) {
        this.$api.email.templates.getWithOrganization({ organizationId: this.account.organizationId })
          .then((response) => {
            this.baseTemplates = response.data
          })
          .catch((err) => {
            this.$snotify.error('Error fetching templates')
          })
      }
    }
  },
  created () {
    this.fetchTemplates()
  },
  watch: {
    account: {
      handler: function () {
        this.fetchTemplates()
      }
    }
  }
}
</script>

<style scoped>
a.badge {
  margin-right: 4px;
}
</style>
